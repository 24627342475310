<template>
    <modal class="printOldToNewModel" :isShow="isShow">
        <div class="header">调整打印机内容 </div>
        <div class="modal-body">
            <div class="li-flex">
                <span class="lable-text">旧打印机：</span>
                <div class="from-input">
                    <el-select v-model="oldPrint" placeholder="请选择">
                        <el-option v-for="(item, index) in pringtList" :key="index" :value="item.Printer_Name" :label="item.label"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="li-flex">
                <span class="lable-text">新打印机：</span>
                <div class="from-input">
                    <el-select v-model="newPrint" placeholder="请选择">
                        <el-option v-for="(item, index) in printOvertiewList" :key="index" :value="item.Print_Autoid" :label="item.label"></el-option>
                    </el-select>
                </div>
            </div>
            <p class="pour">说明:谨慎使用，该功能将【旧打印机】待处理的打印任务变更为【新打印机】</p>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">返回</button>
            <button class="btn selected" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>
// 调整打印机内容
export default {
    name:'printOldToNewModel',
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            /**旧打印机 */
            oldPrint:null,
            /**新打印机 */
            newPrint:null,
            /**有问题的打印机信息 */
            oldPrintList:[],
            /**打印机信息 */
            printOvertiewList:null
        }
    },
    computed:{
        pringtList(){
            let data=this.oldPrintList;
            data?.forEach(it=>{
                it.label= this.printOvertiewList?.find(d=>d.Print_Name==it.Printer_Name)?.label||it.Printer_Name;
            })
            return data;
        }
    },
    mounted(){
        this.init();
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        init(){
            this.oldPrint=null;
            this.newPrint=null;
            this.loadData();
            this.$webBrowser.getPrinter().then(d=>{
                if(d.state==0){
                    this.oldPrintList=d.data;
                }else{
                    this.$message.error(d?.message||"获取打印机失败"); 
                }
            })
        },
        //加载 打印机信息
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPrinterOverview",{
                User_ID:this.$auth.getUserID()
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.printOvertiewList=d.ResponseBody||[];
                    this.printOvertiewList?.forEach(it => {
                        it.label=it.Print_Name+(it.Print_Desc?"("+it.Print_Desc+")":"");
                    });
                    console.log(this.printOvertiewList)
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                console.log('加载打印机信息一览表数据失败：',e);
            })
        },
        confirm(){
            if(!this.oldPrint){
                this.$message.warning('请选择一个旧打印机！');
                return;
            }
            if(!this.newPrint){
                this.$message.warning('请选择一个新打印机！');
                return;
            }

            let newPrint=this.printOvertiewList.find(it=>it.Print_Autoid==this.newPrint);//新打印机
            let oldPrint=this.oldPrintList.find(it=>it.Printer_Name==this.oldPrint);//旧打印机

            if(newPrint.Print_Name==oldPrint.Printer_Name){
                this.$message.warning('两者打印机一样不允许调整！');
                return;
            }
            const loading = this.$loading({
                text: "打印机调整中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$webBrowser.printerNewOldExchange({
                oldPrintName:oldPrint.Printer_Name,
                oldPrintKind:oldPrint.Printer_Type,
                newPrintName:newPrint.Print_Name,
                newPrintKind:newPrint.Print_Kind,
            }).then(d=>{
                loading.close();
                if(d.state==0){
                    this.$message.success("打印机调整成功"); 
                    this.hide();
                }else{
                    this.$message.error("打印机调整失败："+d?.message);
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import './printOldToNewModel.scss'
</style>